import fileDownload from 'js-file-download';
import React, { useRef, useState, useEffect } from 'react';
import DocViewer, { IDocument, DocViewerRenderers } from '@cyntler/react-doc-viewer';

import { LoadingButton } from '@mui/lab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ShieldIcon from '@mui/icons-material/Shield';
import { Box, Button, useTheme, IconButton } from '@mui/material';

import { bulkDownload } from 'src/services/dlxDriveService';
import { DLXDriveBulkDownloadModelType } from 'src/@types/dlx-drive/types';

import Iconify from 'src/components/Iconify';

import 'src/sections/shared/file-viewer.css';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import viewerFileDownload from './viewerFileDownload';
import viewerFileValidation from './viewerFileValidation';
import useNotifications from '../../hooks/useNotifications';
import encryptIDWithRsaPublicKey from './encryptIDWithRsaPublicKey';
import { confirmDownload } from '../../redux/slices/documentHistory';
import DocumentsViewerPDFRenderer from './DocumentsViewerPDFRenderer';
import { FileExtensions } from '../../@types/fileView/enums/fileExtensions';
import { DOC_VIEWER_DOCUMENT_EXTENSIONS } from '../constants/documentsViewerConstants';
import DocumentsViewerNavigation from '../../sections/documents/DocumentsViewerNavigation';
import DocumentFileViewerSkeleton from '../../sections/documents/DocumentFileViewerSkeleton';
import { ValidationData, AdocValidationData } from '../../@types/documents/validationReport';
import DocumentValidationReportModal from '../../sections/documents/DocumentValidationReportModal';
import DocumentAdocValidationReportModal from '../../sections/documents/DocumentAdocValidationReportModal';

// --------------------------------------------------------

type DocumentsViewerProps = {
  docs: IDocument[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  showNavigation: boolean;
  isZooming: boolean;
  setIsZooming: (isZooming: boolean) => void;
};

const DocumentsViewer: React.FC<DocumentsViewerProps> = ({
  docs,
  isLoading,
  setIsLoading,
  showNavigation,
  isZooming,
  setIsZooming,
}) => {
  const { t } = useLocales();
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { document } = useSelector((state) => state.document);
  const [currentDocument, setCurrentDocument] = useState<IDocument>();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>(0);
  const [validationData, setValidationData] = useState<ValidationData>();
  const [adocValidationData, setAdocValidationData] = useState<AdocValidationData>();
  const [validationIsLoading, setValidationIsLoading] = useState(false);

  const wrapper = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const theme = useTheme();

  const handleDocumentChange = async (document: IDocument) => {
    const newActiveDocument = { ...document };
    newActiveDocument.uri = `${encryptIDWithRsaPublicKey(newActiveDocument.uri)}`;

    setCurrentDocument(newActiveDocument);
  };

  const handleWindowResize = () => {
    if (wrapper.current) {
      const width = wrapper.current.offsetWidth;
      const height = wrapper.current.offsetWidth * 1.41;

      if (width !== wrapperSize.width || height !== wrapperSize.height) {
        setWrapperSize({ width, height });
      }
    }
  };

  const handleContainerDownload = async (id: number[]) => {
    const model: DLXDriveBulkDownloadModelType = {
      folderIds: [],
      documentIds: id,
    };

    try {
      const file = await bulkDownload(model);
      if (!document) return;

      const fileName = `${file.fileName}`;

      fileDownload(file.file, fileName);
      enqueueSuccess('dlxDrive.notifications.downloadSuccess');
      await confirmDownload(document?.id || null);
    } catch (error) {
      enqueueError('dlxDrive.errors.downloadError');
    }
  };

  const container = [
    FileExtensions.Adoc,
    FileExtensions.Asic,
    FileExtensions.Bdoc,
    FileExtensions.Edoc,
  ];
  const isContainer = (extension: string) => container.includes(extension as FileExtensions);

  const handleFileDownload = async () => {
    // single file download
    if (currentDocument && document && !isContainer(document.extension)) {
      try {
        await viewerFileDownload(currentDocument);

        enqueueSuccess(t('documents.snackbar.documentSuccessfullyDownloaded'), {
          documentName: currentDocument.fileName,
        });

        await confirmDownload(document?.id || null);
      } catch (error) {
        enqueueError(t('documents.snackbar.errorDownloadingDocument'), {
          documentName: currentDocument.fileName,
        });
      }
    }
    // container download
    if (document && isContainer(document.extension)) {
      await handleContainerDownload([document.id]);
    }
  };

  const handleDocumentValidation = async () => {
    if (document) {
      setValidationIsLoading(true);

      try {
        const extension = (document.extension as FileExtensions) || FileExtensions.Pdf;

        const data = await viewerFileValidation(document.id, document.name, extension);

        if (extension === FileExtensions.Adoc) {
          setAdocValidationData(data);
        } else {
          setValidationData(data);
        }

        enqueueSuccess(t('documents.snackbar.documentSuccessfullyValidated'), {
          documentName: document.name,
        });
      } catch (error) {
        enqueueError(t('documents.snackbar.documentValidationFailed'), {
          documentName: document.name,
        });
      } finally {
        setValidationIsLoading(false);
      }
    }
  };

  const handleValidationReportClose = () => {
    setValidationData(undefined);
    setAdocValidationData(undefined);
  };

  useEffect(() => {
    setCurrentDocumentIndex(0);

    if (docs.length > 0) {
      handleDocumentChange(docs[0]);
    }
  }, [docs]);

  useEffect(() => {
    setIsLoading(true);

    handleDocumentChange(docs[currentDocumentIndex]);
  }, [currentDocumentIndex]);

  useEffect(() => {
    handleWindowResize();
  }, [wrapper.current]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      {!isZooming && (
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2, my: 2 }}>
          <Button
            onClick={handleFileDownload}
            type="button"
            variant="contained"
            color="secondary"
            startIcon={<Iconify icon="eva:download-fill" width={22} height={22} />}
            id="ovjbyt9b"
          >
            {t('documents.buttons.download')}
          </Button>

          <LoadingButton
            onClick={handleDocumentValidation}
            loading={validationIsLoading}
            type="button"
            variant="outlined"
            color="secondary"
            id="vks4aqks"
            startIcon={<ShieldIcon fontSize="small" />}
          >
            {t('documents.buttons.validate')}
          </LoadingButton>
        </Box>
      )}

      <Box sx={{ width: '100%', position: 'relative' }}>
        {showNavigation && docs.length > 0 && (
          <Box
            sx={{
              p: 1,
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              backgroundColor: theme.palette.grey[0],
              borderBottom: `1px dashed ${theme.palette.grey[300]}`,
            }}
          >
            <DocumentsViewerNavigation
              docs={docs}
              currentDocumentIndex={currentDocumentIndex}
              setCurrentDocumentIndex={setCurrentDocumentIndex}
            />
          </Box>
        )}

        <Box
          ref={wrapper}
          sx={{
            width: '100%',
            minHeight: `${wrapperSize.height}px`,
            boxShadow: '2px 3px 4px #cccccc',
          }}
        >
          {!isLoading &&
            currentDocument &&
            currentDocument.fileType &&
            DOC_VIEWER_DOCUMENT_EXTENSIONS.includes(currentDocument.fileType) && (
              <DocViewer
                documents={[currentDocument]}
                pluginRenderers={DocViewerRenderers}
                onDocumentChange={handleDocumentChange}
                prefetchMethod="GET"
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                }}
                style={{
                  width: `${wrapperSize.width}px`,
                  height: `${wrapperSize.height}px`,
                }}
              />
            )}

          {!isLoading &&
            currentDocument &&
            currentDocument.fileType &&
            !DOC_VIEWER_DOCUMENT_EXTENSIONS.includes(currentDocument.fileType) && (
              <DocumentsViewerPDFRenderer
                currentDocument={currentDocument}
                wrapperSize={wrapperSize}
              />
            )}

          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#fff',
              }}
            >
              <DocumentFileViewerSkeleton wrapperSize={wrapperSize} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            py: 1,
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            backgroundColor: theme.palette.grey[0],
            borderTop: `1px dashed ${theme.palette.grey[300]}`,
          }}
        />

        {!isZooming && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 40,
              left: 20,
              zIndex: 9003,
              cursor: 'pointer',
            }}
          >
            <IconButton
              size="large"
              onClick={() => setIsZooming(true)}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.1)',
              }}
            >
              <ZoomInIcon fontSize="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>

      {validationData && (
        <DocumentValidationReportModal
          data={validationData}
          handleClose={handleValidationReportClose}
        />
      )}

      {adocValidationData && (
        <DocumentAdocValidationReportModal
          data={adocValidationData}
          handleClose={handleValidationReportClose}
        />
      )}
    </>
  );
};

export default DocumentsViewer;
